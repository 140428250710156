import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { GatsbyImage } from 'gatsby-plugin-image'

const PurchaseBackgroundImage = ({ data, alt, ...props }) => {
  return (
    <GatsbyImage
      alt='Acheter un local professionnel'
      image={get(data, 'placeholderImage.childImageSharp.gatsbyImageData')}
      {...props} />
  )
}

PurchaseBackgroundImage.propTypes = {
  alt: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
}

const StyledImage = styled(PurchaseBackgroundImage)`
  position: absolute;
  width: 994px;
  height: auto;
  top: 39%;
  right: 0;
  transform: translateY(-35%);
`

export default StyledImage
