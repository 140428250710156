import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get } from 'lodash'

import media from '../../../utils/media'
import Bullets from '../../atoms/Bullets'

const Header = styled.h1`
  font-family: 'Source Sans Pro';
  font-size: 30px;
  font-weight: bold;
  line-height: normal;
  margin-bottom: 24px;
  text-shadow: 0 3px 6px rgba(18, 59, 178, 0.79);
  color: ${({ theme }) => get(theme, 'white', '#FFF')};
  text-align: ${props => get(props, 'center', false) ? 'center' : 'left'};

  ${media.greaterThan('sm')`
    font-size: 40px;
    margin-bottom: 32px;
  `}
`
const Subtitle = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-family: 'Source Sans Pro';
  font-size: 16px;
  font-weight: 600;
  margin: 16px 0 24px 0;
  line-height: normal;
  text-shadow: 0 3px 6px rgba(18, 59, 178, 0.79);
  color: ${({ theme }) => get(theme, 'white', '#FFF')};

  ${media.greaterThan('sm')`
    font-size: 18px;
  `}
`
const Container = styled.div`
  width: 100%;
  max-width: 600px;

  ${media.lessThan('md')`
    width:90%;
  `}
`
const StyledContainer = styled(Container)`
  max-width: 650px;
  margin: auto;
  height: 700px;
  padding-top: 180px;
  
  ${media.greaterThan('md')`
    padding-top: 180px;
  `}
  
  ${media.greaterThan('xl')`
    max-width: 600px;
    margin-right: 45%;
    padding-top: 160px;
  `}

  ${media.greaterThan('xxl')`
    max-width: 580px;
    padding-top: 180px;
    margin-right: 50%;
  `}
`

const TopSectionContent = ({ title, bullets, Button }) => (
  <StyledContainer>
    <Header>{title}</Header>
    <Subtitle>
      <Bullets bullets={bullets} />
    </Subtitle>
    <Button />
  </StyledContainer >
)

TopSectionContent.propTypes = {
  title: PropTypes.string.isRequired,
  Button: PropTypes.any,
  bullets: PropTypes.array
}

export default TopSectionContent
