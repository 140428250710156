import React from 'react'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'

import ImageSection from '../../molecules/ImageSection'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const ServicePresentation = ({ data, alt, title, subtitle, content, button }) => {
  return (
    <ImageSection
      alt={alt}
      size={580}
      fixed={false}
      right={true}
      imageData={data}>
      <HomeSectionTitle tag='h2' dangerouslySetInnerHTML={{ __html: title }} />
      {!isEmpty(subtitle) &&
        <HomeSectionSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />}
      {!isEmpty(content) &&
        <HomeSectionDescription dangerouslySetInnerHTML={{ __html: content }} />}
      {!isEmpty(button) &&
        <ButtonContainer>
          <LaunchButton
            link={get(button, 'link')}
            title={get(button, 'title')}
            background='brightOrange' />
        </ButtonContainer>}
    </ImageSection>
  )
}

ServicePresentation.propTypes = {
  alt: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  button: PropTypes.object,
  content: PropTypes.string,
  subtitle: PropTypes.string
}

export default ServicePresentation
