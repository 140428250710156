import React from 'react'
import { get } from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'

import ServicePresentation from '../ServicePresentation'

const purchaseData = {
  alt: 'Mettez votre local professionnel à louer',
  title: 'Mettez votre local professionnel à louer',
  content: `Que ce soit pour la totalité d’un local à temps plein ou simplement un cabinet/bureau à temps partiel, vous pourrez trouver un locataire intéressé par votre bien.<br/>
  C’est pour cela que nos experts vous accompagne de la définition de vos besoins, en passant par l’évaluation de votre budget, jusqu’à la signature chez le notaire.<br/>
  Il vous suffit de donner les caractéristiques des locaux que vous souhaitez louer (surface, étage, équipements, loyer, activité, localisation) et de mettre quelques photos afin de déposer l’annonce sur notre plateforme.<br/>
  Vous serez contacté directement par des professionnels libéraux pertinents.`,
  subtitle: 'Sur notre plateforme Trouver Mon Local Pro, vous avez la possibilité de déposer votre annonce de location de votre local professionnel.'
}

const LocationService = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "location/service.webp"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <ServicePresentation
      alt={get(purchaseData, 'alt')}
      data={placeholderImage}
      title={get(purchaseData, 'title')}
      content={get(purchaseData, 'content')}
      subtitle={get(purchaseData, 'subtitle')}
      button={{
        link: '/nos-annonces/deposer-une-annonce',
        title: 'Déposer mon annonce de location'
      }} />
  )
}

export default LocationService
