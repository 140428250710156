import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { BgImage } from 'gbimage-bridge'
import { getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

import Wave from '../../../assets/wave.inline.svg'
import media from '../../../utils/media'

const Container = styled.div`
  position: relative;
  width: 100%;
`

const StyledWave = styled(Wave)`
  position: absolute;
  left: 0;
  bottom: -1px;
  width: 100%;
  height: auto;

  ${media.greaterThan('xl')`
    width: 70%;
  `}
`

const Content = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 80px;
  padding: 120px 0;

  ${media.greaterThan('xl')`
    padding: 96px;
  `}
`

const TopSection = ({ className, children, component: Component, absoluteImage }) => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "background.png"}) {
      childImageSharp {
        gatsbyImageData(
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  const image = getImage(placeholderImage)
  const images = [
    image,
    'linear-gradient(130deg, #2c71ed, #2c71ed)'
  ]

  if (absoluteImage) {
    return (
      <Container>
        <BgImage
          Tag='div'
          image={images}
          className={className}
          preserveStackingContext >
          {children}
          <StyledWave />
        </BgImage>
        <Component />
      </Container>
    )
  }

  return (
    <Container>
      <BgImage
        Tag='div'
        image={images}
        className={className}
        preserveStackingContext >
        <Content>
          {children}
          <Component />
        </Content>
        <StyledWave />
      </BgImage>
    </Container>
  )
}

TopSection.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  component: PropTypes.any,
  absoluteImage: PropTypes.bool
}

TopSection.defaultProps = {
  absoluteImage: true
}

const StyledTopSection = styled(TopSection)`
  width: 100%;
  background-position: top left, center;
  background-repeat: repeat, no-repeat;
  background-size: contain, cover;
`

export default StyledTopSection
