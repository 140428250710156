import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../../components/atoms/Seo'
import Footer from '../../components/organisms/Footer'
import Navbar from '../../components/organisms/Navbar'
import HomeAds from '../../components/organisms/HomeAds'
import Categories from '../../components/organisms/Categories'
import SiteSection from '../../components/atoms/SiteSection'
import LocationSearch from '../../components/organisms/LocationSearch'
import LocationService from '../../components/organisms/LocationService'
import LocationPartTime from '../../components/organisms/LocationPartTime'
import LocationAdvantages from '../../components/organisms/LocationAdvantages'
import InlineBlockPresentation from '../../components/organisms/InlineBlockPresentation'

const TITLE = 'Louer un local professionnel'
const DESCRIPTION = 'Louer un local professionnel'

const locationCategories = [{
  link: '/nos-annonces/vendre',
  title: 'Vente Cabinet Médical',
  image: 'medical'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Cabinet Para-Médical',
  image: 'para-medical'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Local 2 pièces',
  image: 'local-t2'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Local 3 pièces',
  image: 'local-t3'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Local Paris',
  image: 'paris'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Local Lyon',
  image: 'lyon'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Local Montpellier',
  image: 'montpellier'
}, {
  link: '/nos-annonces/vendre',
  title: 'Vente Local Nantes',
  image: 'nantes'
}]

const locationBlocs = [{
  html: 'Trouver Mon Local Pro est la 1ère plateforme qui rassemble les offres de locaux à destination des professions libérales. Identifiez en quelques minutes tous les locaux à louer qui pourraient vous correspondre.',
  link: '/nos-annonces/louer/?action=louer',
  title: 'Louer un local adapté',
  subtitle: 'aux professions libérales',
  image: 'location-1',
  button: 'Voir les locations'
}, {
  html: 'Vous souhaitez louer un local ? Vous pouvez dès maintenant déposer une annonce pour le mettre en avant auprès de milliers de professionnels libéraux dans toute la France.',
  link: '/nos-annonces/deposer-une-annonce',
  title: 'Trouver des locataires',
  subtitle: 'pour vos locaux professionnels',
  image: 'location-2',
  button: 'Déposer une annonce'
}]

const SellPage = ({ location }) => {
  return (
    <>
      <SEO
        title={TITLE}
        description={DESCRIPTION} />
      <Navbar location={location} />
      <LocationSearch />
      <SiteSection id='services-presentation' margin='100px 0 0 0'>
      <InlineBlockPresentation
          blocs={locationBlocs}
          title='Louez votre futur local seul ou accompagné' />
      </SiteSection>
      <SiteSection id='mettre-local-pro-a-louer' margin='120px 0 0 0'>
        <LocationService />
      </SiteSection>
      <SiteSection id='dernieres-nouveautes' margin='120px 0 0 0'>
        <HomeAds type='ad-location' title='Nos derniers locaux à louer' />
      </SiteSection>
      <SiteSection id='avantages-location-local-pro' margin='120px 0 0 0'>
        <LocationAdvantages />
      </SiteSection>
      <SiteSection id='tous-nos-locaux-a-vendre' margin='120px 0 0 0'>
        <Categories title='Tous nos locaux professionnels à vendre' categories={locationCategories} />
      </SiteSection>
      <SiteSection id='location-temps-partiel' margin='50px 0 80px 0'>
        <LocationPartTime />
      </SiteSection>
      {/* <SiteSection id='avis' margin='60px 0 80px 0 '>
        <HomeReviews title='Ils nous ont fait confiance' />
      </SiteSection> */}
      <Footer />
    </>
  )
}

SellPage.propTypes = {
  location: PropTypes.object.isRequired
}

export default SellPage
