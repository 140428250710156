import React from 'react'

import WhiteBlock from '../WhiteBlock'

const LocationAdvantages = () => {
  return (
    <WhiteBlock
      title='Les avantages de la location d’un local professionnel'
      content='En 1er lieu, la location vous apporte de la flexibilité.<br/>
      Si vous souhaitez travailler en libéral à temps partiel et en même temps dans une autre structure ou dans une ville différente, louer un local sera beaucoup plus sécurisant et adapté à votre besoin.<br/>
      La location d’un local professionnel est également moins risquée qu’un achat.<br/>
      Dans le cas d’un lancement en libéral, cela vous permet de ne pas être engagé sur une longue durée et donc de pouvoir vous adapter en fonction du développement de votre activité.'
      subtitle=''
      button={{
        link: '/nos-annonces/louer/?action=louer',
        title: 'Voir les locaux à louer'
      }} />
  )
}

export default LocationAdvantages
