import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, map } from 'lodash'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
const Inline = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const BulletPoint = styled.div`
  min-width: 18px;
  min-height: 18px;
  border: 4px solid ${({ theme }) => get(theme, 'brightOrange', '#fff')};
  border-radius: 50px;
`

const Bullets = ({ bullets, ...props }) => {
  return (
    <Container {...props}>
      {map(bullets, bullet => {
        return (
          <Inline key={bullet}>
            <BulletPoint />
            <p>{bullet}</p>
          </Inline>
        )
      })}
    </Container>
  )
}

Bullets.propTypes = {
  bullets: PropTypes.array.isRequired
}

export default Bullets
