import React, { useEffect, useCallback } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { get, map, isEqual, isEmpty, keys, filter, isUndefined } from 'lodash'

import theme from '../../../config/theme'
import media from '../../../utils/media'
import LocationSuggestions from '../LocationSuggestions'

const InlineSelections = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
`

const Selection = styled.div`
  color: ${({ theme }) => get(theme, 'white', '#fff')};
  padding: 8px;
  font-family: 'Source Sans Pro';
  border-radius: 4px;
  background-color: ${({ theme }) => get(theme, 'blue', '#fff')};
  display: flex;
  gap: 8px
`

const RemoveLocationIcon = styled(FontAwesomeIcon)`
  cursor: pointer;
`

const Selections = ({ location, removeLocation }) => {
  if (isEmpty(get(location, 'city')) &&
    isEmpty(get(location, 'region')) &&
    isEmpty(get(location, 'agglomeration')) &&
    isEmpty(get(location, 'department'))) {
    return null
  }

  return (
    <InlineSelections>
      {map(keys(location), key => {
        return map(location[key], elt => {
          if (isUndefined(get(elt, 'name'))) {
            return null
          }

          return (
            <Selection key={get(elt, 'id')}>
              <>{get(elt, 'name')}</>
              <RemoveLocationIcon icon='times' color={get(theme, 'white', '#fff')} onClick={() => removeLocation(key, get(elt, 'id'))} />
            </Selection>
          )
        })
      })}
    </InlineSelections>
  )
}

Selections.propTypes = {
  location: PropTypes.object.isRequired,
  removeLocation: PropTypes.func.isRequired
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
  min-width: 300px;

  ${media.lessThan('md')`
    min-width: 100%;
  `}
`

const Input = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: ${({ theme }) => get(theme, 'white')};
  margin-bottom: ${({ error }) => error ? 5 : 2}px;
  color: ${({ error, theme }) => get(theme, error ? 'lightOrange' : 'blue')};
  border-radius: 48px;
  padding: 10px 16px;
  flex-wrap: wrap;
  box-shadow: 0 3px 6px 0 rgba(12, 62, 208, 0.45);

  ${media.lessThan('lg')`
    width: 100%;
  `}
`

const StyledInput = styled.input`
  font-family: 'Source Sans Pro';
  font-size: 18px;
  line-height: 1.2;
  height: auto;
  margin: 0;
  color: ${({ error, theme }) => get(theme, error ? 'lightOrange' : 'blue')};
  border-radius: 48px;
  border: 0px solid transparent;
  outline: none;

  &::placeholder {
    height: 25px;
    font-size: 18px;
    line-height: 1.2;
    font-family: 'Source Sans Pro';
    opacity: 0.5;
    color: ${({ error, theme }) => get(theme, error ? 'lightOrange' : 'blue')};
  }
`

const Wrapped = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`

const LocationInputHome = ({ icon, location, locationProps, setFieldValue }) => {
  const updateLocation = useCallback((selected) => {
    const type = get(selected, 'type')
    const toUpdate = get(location, type)

    setFieldValue('location', {
      ...location,
      [type]: [...toUpdate, selected]
    })
    setFieldValue('locationInput', '')
  }, [locationProps, setFieldValue])

  const removeLocation = useCallback((type, id) => {
    const toUpdate = filter(get(location, type), elt => !isEqual(get(elt, 'id'), id))
    setFieldValue('location', {
      ...location,
      [type]: toUpdate
    })
  }, [location])

  useEffect(() => {
  }, [location])

  return (
    <Container>
      <Input>
        <Wrapped>
          <FontAwesomeIcon icon={icon} color={get(theme, 'blue', '#fff')} />
          <Selections
            location={location}
            removeLocation={removeLocation} />
        </Wrapped>
        <StyledInput
          {...locationProps} />
      </Input>
      <LocationSuggestions
        input={get(locationProps, 'value', '')}
        updateLocation={updateLocation} />
    </Container>
  )
}

LocationInputHome.propTypes = {
  icon: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  suggestions: PropTypes.object,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  locationProps: PropTypes.object.isRequired
}

export default LocationInputHome
