import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'
import { useFormik } from 'formik'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  get, isEmpty, isEqual, map, reduce
} from 'lodash'

import media from '../../../utils/media'
import theme from '../../../config/theme'
import SubmitButton from '../../atoms/SubmitButton'
import LocationInputHome from '../../molecules/LocationInputHome'

const Inline = styled.div`
  display: flex;
  flex-direction: row;
  align-items: ${({ center }) => center ? 'center' : 'baseline'};
  justify-content: center;
  ${({ gap }) => gap ? `gap: ${gap}px` : ''};

  ${media.lessThan('md')`
    gap: 4px;
    flex-direction: column;
    ${({ button }) => button && 'margin: auto;'}
  `}
`

const Button = styled(SubmitButton)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.2;
  padding: 11px 32px;
  margin-top: 16px;
  border-radius: 48px;
  border: 1px solid transparent;
  box-shadow: 0 3px 6px 0 rgba(12, 62, 208, 0.45);

  ${media.greaterThan('md')`
    margin: 0;
  `}

  ${media.lessThan('md')`
    margin-top: 8px;
    
  `}
`

const Icon = styled(FontAwesomeIcon)`
  && {
    width: 18px;
    height: 18px;
    margin-right: 8px;
  }
`

const SearchCity = ({ action, ...props }) => {
  const { values, handleSubmit, handleChange, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      location: {
        city: [],
        region: [],
        department: [],
        agglomeration: []
      }
    },
    enableReinitialize: true,
    onSubmit: async values => {
      if (isEqual(action, 'vendre')) {
        return navigate('/nos-annonces/deposer-une-annonce')
      }

      const locationParams = reduce(get(values, 'location'), (acc, val, key) => {
        if (isEmpty(val)) {
          return acc
        }

        const queryParamsForVal = map(val, iter => `${key}=${get(iter, 'id')}`)

        return `${acc}${queryParamsForVal.join('&')}&`
      }, '')

      const url = `${action}/?action=${action}&${locationParams}`
      return navigate(`/nos-annonces/${url}`)
    }
  })

  return (
    <Inline gap={12}>
      <LocationInputHome
        icon='crosshairs'
        location={get(values, 'location')}
        locationProps={{
          name: 'locationInput',
          value: get(values, 'locationInput', ''),
          onBlur: handleBlur,
          onChange: handleChange,
          placeholder: 'Où cherchez vous ?'
        }}
        setFieldValue={setFieldValue} />
      <Inline button={true}>
        <Button
          type='button'
          onClick={handleSubmit}>
          <Icon
            icon='search'
            color={get(theme, 'white')} />
          <span>
            Découvrir nos locaux
          </span>
        </Button>
      </Inline>
    </Inline>
  )
}

SearchCity.propTypes = {
  action: PropTypes.string.isRequired
}

export default SearchCity
