import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, map } from 'lodash'

import media from '../../../utils/media'
import LaunchButton from '../../atoms/LaunchButton'
import CategoryImage from '../../atoms/CategoryImage'

const CategoryContainer = styled.div`
  display: flex;
  justify-content: center;
  width: ${({ size }) => get(size, 'sm.width')};
  height: auto;
  box-shadow: 0 3px 6px 0 rgba(0, 73, 238, 0.15);
  border-radius: 18px;
  
  ${media.lessThan('sm')`
    width: ${({ size }) => get(size, 'xs.width')};
  `}

  ${media.greaterThan('md')`
    width: ${({ size }) => get(size, 'md.width', 'auto')};
  `}

  ${media.greaterThan('lg')`
  `}
`

const CategoryCard = styled.div`
  border-radius: 18px;
  background-color: #ffffff;
  text-decoration: none;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const CategoryTitleContainer = styled.div`
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
`

const CategoryTitle = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  margin: 8px 0;
  text-align: center;
  color: ${({ theme }) => get(theme, 'blue', '#fff')};
  flex-grow: 1

  ${media.lessThan('sm')`
    font-size: 16px;
  `}
`

const CategoryText = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 16px;
  line-height: 1.4;
  color: ${({ theme }) => get(theme, 'blue', '#fff')};

  & > strong {
    font-weight: 600;
  }

  & > span a {
    color: ${({ theme }) => get(theme, 'brightOrange', '#fff')};
    text-decoration: none;
  }

  ${media.lessThan('sm')`
    font-size: 15px;
  `}
`

const CategoryButton = styled(LaunchButton)`
  margin: 0 12px 10px 12px;
  font-size: 16px;
  padding: 2px;
  min-height: 38px;
`

const Category = ({ categories, size }) => map(categories, ({ title, image, link, html, button }) => (
  <CategoryContainer key={`Category-${title}`} size={size}>
    <CategoryCard key={`Category-${title}`}>
      <CategoryImage name={image} />
      <CategoryTitleContainer>
        <CategoryTitle html={html}>{title}</CategoryTitle>
        <CategoryText dangerouslySetInnerHTML={{ __html: html }} />
      </CategoryTitleContainer>
      {button && <CategoryButton
        link={link}
        title={button}
        background='brightOrange' />}
    </CategoryCard>
  </CategoryContainer>
))

Category.propTypes = {
  size: PropTypes.object,
  categories: PropTypes.array.isRequired
}

Category.defaultProps = {
  size: {
    xs: {
      width: '90%'
    },
    sm: {
      width: '80%'
    },
    md: {
      width: 'auto'
    }
  }
}

export default Category
