import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import PresentationBlocs from '../PresentationBlocs'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 32px;
`

const CenterTitle = styled(HomeSectionTitle)`
  text-align: center;
`

const InlineBlockPresentation = ({ title, blocs }) => {
  return (
    <Container>
      <CenterTitle tag='h2'>
        {title}
      </CenterTitle>
      <PresentationBlocs blocs={blocs} />
    </Container>
  )
}

InlineBlockPresentation.propTypes = {
  blocs: PropTypes.array.isRequired,
  title: PropTypes.string
}

export default InlineBlockPresentation
