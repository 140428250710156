import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import ImageSection from '../../molecules/ImageSection'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const size = {
  lg: {
    height: '503'
  }
}

const LocationPartTime = () => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "location/mid-time.webp"}) {
      childImageSharp {
        gatsbyImageData(
          placeholder: BLURRED
        )
      }
    }
  }`)

  return (
    <ImageSection
      alt='Louer un local à temps partiel'
      size={size}
      fixed={false}
      right={false}
      imageData={placeholderImage}
      maxText={580}>
      <HomeSectionTitle tag='h2'>
        Louer un local à temps partiel
      </HomeSectionTitle>
      <HomeSectionSubtitle>
        Il est de plus en plus courant que des professionnels libéraux souhaitent disposer de locaux seulement pour une demie-journée ou jusqu’à 4 jours par semaine.
      </HomeSectionSubtitle>
      <HomeSectionDescription>
        Utile lorsque l’on souhaite avoir des locaux dans plusieurs localisations différentes, travailler à temps partiel dans une structure, lancer son activité libérale sans avoir trop de charges ou également pour les professionnels qui travaillent à mi-temps.<br />
        Il est nécessaire dans ces cas de trouver un cabinet ou bureau disponible à temps partiel dans votre zone de recherche.<br />
        Identifiez dès maintenant les locaux à louer sur notre plateforme.
      </HomeSectionDescription>
      <ButtonContainer>
        <LaunchButton
          link='/nos-annonces/louer/?action=louer'
          title='Voir les locaux à louer'
          background='brightOrange' />
      </ButtonContainer>
    </ImageSection>
  )
}

export default LocationPartTime
