import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import media from '../../../utils/media'
import Category from '../../molecules/Category'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'

const Container = styled.div`
`
const HomeContainer = styled.div`
  width: 100%;
  max-width: ${({ max }) => max}px;
  margin: 0 auto;
  padding: 0 24px;


  ${media.lessThan('sm')`
    padding: 0px 24px;
  `}

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}
  ${media.greaterThan('md')`
    padding: 0 72px;
  `}
  ${media.greaterThan('lg')`
    padding: 0 120px;
  `}
`

const DetailsContainer = styled.div`
  display: flex;
  margin: 32px auto;
  justify-content: center;
  flex-wrap: wrap;
  padding: 32px 24px;
  gap: 24px;

  ${media.lessThan('lg')`
    padding: 8px 0;
  `}

  ${media.lessThan('sm')`
    align-items: center;
    flex-direction: column;
  `}

  ${media.greaterThan('md')`
    flex-wrap: wrap;
    margin: 0 auto;
  `}

  ${media.greaterThan('lg')`
    width: 95%;
  `}
`

const Categories = ({ size, title, categories }) => (
  <Container>
    <HomeContainer>
      <HomeSectionTitle tag='h2'>{title}</HomeSectionTitle>
    </HomeContainer>
    <DetailsContainer>
      <Category
        size={size}
        categories={categories} />
    </DetailsContainer>
  </Container>
)

Categories.propTypes = {
  size: PropTypes.object,
  title: PropTypes.string.isRequired,
  categories: PropTypes.array.isRequired
}

Categories.defaultProps = {
  size: {
    xs: {
      width: '90%'
    },
    sm: {
      width: '30%'
    },
    md: {
      width: '20%'
    }
  }
}
export default Categories
