import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { get, isEmpty } from 'lodash'

import media from '../../../utils/media'
import LaunchButton from '../../atoms/LaunchButton'
import ButtonContainer from '../../atoms/ButtonContainer'
import HomeSectionTitle from '../../atoms/HomeSectionTitle'
import HomeSectionSubtitle from '../../atoms/HomeSectionSubtitle'
import HomeSectionDescription from '../../atoms/HomeSectionDescription'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 830px;
  margin: 0 auto;
  padding: 0 24px ;

  ${media.greaterThan('sm')`
    padding: 0 40px;
  `}

  ${media.greaterThan('md')`
    padding: 0 72px ;
  `}

  ${media.greaterThan('lg')`
    padding: 0;
  `}
`

const WhiteBlock = ({ title, subtitle, content, button }) => (
  <Container>
    <HomeSectionTitle tag='h2' dangerouslySetInnerHTML={{ __html: title }} />
    {!isEmpty(subtitle) &&
      <HomeSectionSubtitle dangerouslySetInnerHTML={{ __html: subtitle }} />}
    {!isEmpty(content) &&
      <HomeSectionDescription start={subtitle} dangerouslySetInnerHTML={{ __html: content }} />}
    {!isEmpty(button) &&
      <ButtonContainer>
        <LaunchButton
          link={get(button, 'link')}
          title={get(button, 'title')}
          background='brightOrange' />
      </ButtonContainer>}
  </Container>
)

WhiteBlock.propTypes = {
  title: PropTypes.string.isRequired,
  button: PropTypes.object,
  content: PropTypes.string,
  subtitle: PropTypes.string
}

export default WhiteBlock
