import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

import media from '../../../utils/media'
import TopSection from '../../molecules/TopSection'
import SearchCity from '../../molecules/SearchCity'
import BackgroundImage from '../../molecules/BackgroundImage'
import TopSectionContent from '../../molecules/TopSectionContent'

const BULLETS = [
  'Des annonces dédiées aux locaux professionnels',
  'Louez un local entier ou juste un cabinet',
  'Des alertes pour recevoir les nouveautés'
]

const StyledBackground = styled(BackgroundImage)`
  z-index: -1;
  display: none;

  ${media.greaterThan('xl')`
    display: block;
    height: 700px;
    top: 245px;
  `}
`

const LocationSearch = () => {
  const data = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "location/top-section.jpg"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  return (
    <TopSection component={() =>
      <StyledBackground alt='Louer un local professionnel' data={data} />}>
      <TopSectionContent
        title='Louer un local professionnel n’a jamais été aussi simple'
        bullets={BULLETS}
        Button={() => <SearchCity action='louer' />} />
    </TopSection>
  )
}

export default LocationSearch
